@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input-span-login {
    @apply text-sm text-zinc-700 px-3 translate-y-[43px] pointer-events-none transition-all;
  }
  .input-login {
    @apply px-3 pt-5 pb-1 my-2 rounded-md focus:outline-none border-[1px] min-w-[10px] focus:px-2 focus:border-4 border-primary border-opacity-40 transition-all;
  }

  .skeleton {
    @apply bg-gray-300 mt-3 rounded-md animate-pulse;
  }
  .skeleton.field {
    @apply h-8 m-4 w-11/12 justify-self-center;
  }
  .skeleton.text {
    @apply w-full h-3;
  }
  .skeleton.title {
    @apply w-1/2 h-5 mb-4;
  }
  .skeleton.avatar {
    @apply w-24 h-24 rounded-full;
  }
  .skeleton.thumbnail {
    @apply w-24 h-24;
  }

  .nfse-input-outer-container {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4 p-4 w-full rounded-md border-2 mt-8;
  }
  .nfse-input-container {
    @apply flex flex-col w-full justify-center;
  }
  .nfse-input {
    @apply w-full bg-gray-50 font-medium text-sm border-2 disabled:bg-gray-200 disabled:border-gray-400 disabled:text-gray-500 rounded-md p-2 focus:outline-none;
  }
  .nfse-input-select {
    @apply nfse-input uppercase ellipsis;
  }
  .nfse-input-error {
    @apply border-red-500 bg-red-100 shadow shadow-red-400;
  }
  .nfse-input-label {
    @apply font-semibold text-sm md:text-base whitespace-nowrap p-2 text-black;
  }

  .button {
    @apply p-2 px-3 mt-2 w-fit self-end text-lg rounded-md bg-primary hover:bg-opacity-80 transition-all hover:shadow-md text-[#fff] disabled:bg-gray-300 disabled:hover:shadow-none;
  }

  .ellipsis {
    @apply whitespace-nowrap overflow-ellipsis overflow-hidden;
  }
}

/* CLEAN AUTOCOMPLETE BACKGROUND COLOR */
input:-webkit-autofill {
  -webkit-background-clip: text;
}

/* width */
.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #2a7ae3;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0049a9;
}
